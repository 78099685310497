import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="Dialog">
        <div className="Splash">&nbsp;</div>
        <a href="/policy.html">POLICY</a>&nbsp;|&nbsp;
        <a href="mailto:printii@printii.com">CONTACT</a>
      </div>
    </div>
  );
}

export default App;
